<template>
	<div>
		<Header></Header>
		<div class="common-header">
			<div class="search-healder">
				<div class="logo-box">
					<img @click="toUrl('/')" class="logo" src="../../image/index/logo.png" />
				</div>
				<div class="search">
					<input placeholder="请输入商品名称" v-model="name" />
					<div class="search-btn el-icon-search" @click="search()"> </div>
				</div>
			</div>
		</div>
		<div class="order-confirm-content">
			<div class="addr-box">
				<div class="header-wrapper border-bottom">
					<h2 class="header-title">确认收货地址
						<span class="header-operation" @click="addr()">管理收货地址</span>
					</h2>
				</div>
				<div class="addr-list">
					<el-radio-group v-model="addrIndex">
						<div v-for="(item,index) in addrs" :key="index" :class="index==addrIndex?'addr-select':''">
							<el-radio :label="index">
								<span class="addr">{{item.province}}{{item.city}}{{item.region}}{{item.address}}</span>
								<span class="name">({{item.name}})</span>
								<span class="phone">{{item.phone}}</span>
							</el-radio>
						</div>
					</el-radio-group>
				</div>
			</div>
			<div class="item-box">
				<div class="header-wrapper border-bottom">
					<h2 class="header-title">确认订单信息
					</h2>
				</div>
				<div class="item-headers-wrap item-headers-column-6">
					<div class="item-headers-content item-headers-0">商品</div>
					<div class="item-headers-content item-headers-1">商品属性</div>
					<div class="item-headers-content item-headers-2">单价</div>
					<div class="item-headers-content item-headers-3">数量</div>
					<div class="item-headers-content item-headers-5">小计</div>
				</div>
				<div class="item-row">
					<div v-for="(itemList,itemIndex) in orderData" :key="itemIndex">
						<div class="item-info" v-for="(item,index) in itemList.list" :key="index">
							<div class="item-detail info-cell">
								<div class="td-inner">
									<div class="info-img">
										<img :src="item.skuPic" v-if="orderData[0].hasPreDrug==0" />
										<img src="../../assets/drug.jpg" v-if="orderData[0].hasPreDrug==1" />
									</div>
									<div class="info-title">
										<div class="item-basic-info">
											{{item.itemName}}
										</div>
									</div>
								</div>
							</div>
							<div class="info-sku info-cell">
								<span class="bd">{{item.skuName}}</span>
							</div>
							<div class="info-price info-cell">{{item.isPrice}}</div>
							<div class="order-quantity">{{item.count}}</div>
							<div class="item-row__price">{{item.isPrice*item.count}}</div>
						</div>
					</div>
					<div class="demo-input-suffix" style="margin-top: 20px;">
						订单备注：
						<el-input v-if="!showPay" style="width: 400px;" type="textarea" :rows="4"
							placeholder="选填，请先和商家协商一致，付款后商家可见" v-model="remark">
						</el-input>
						<span v-if="showPay">{{remark}}</span>
					</div>
					<div class="demo-input-suffix prd-drug" style="margin-top: 20px;"
						v-if="orderData.length>0&&orderData[0].hasPreDrug==1">
						<div class="recipel">
							<div class="tip">请填写与您处方单一致的个人信息,以便后台核实</div>
							<el-form ref="recipel" :model="recipel" label-width="80px">
								<el-form-item label="姓名" prop="name"
									:rules="[{ required: true, message: '请输入', trigger: ['blur', 'change'] }]">
									<el-input v-model="recipel.name"></el-input>
								</el-form-item>
								<el-form-item label="联系电话" prop="phone"
									:rules="[{ required: true, message: '请输入', trigger: ['blur', 'change'] }]">
									<el-input v-model="recipel.phone"></el-input>
								</el-form-item>
								<el-form-item label="身份证号" prop="card"
									:rules="[{ required: true, message: '请输入', trigger: ['blur', 'change'] }]">
									<el-input v-model="recipel.card"></el-input>
								</el-form-item>
							</el-form>
						</div>
					</div>
					<div class="total">
						<span>运费合计:</span>
						<span class="price">￥{{getFrePrice()}}</span>
					</div>
					<div class="total">
						<span>商品合计(含运费):</span>
						<span class="price">￥{{getPrice()}}</span>
					</div>
				</div>
				<div class="pay-box" v-if="!showPay">
					<div class="box__wrapper">
						<div class="box__shadow">
							<div class="pay-price">
								实付款:<span>￥{{getPrice()}}</span>
							</div>
							<div class="addr" v-if="addrs.length>0">
								寄送至:<span>
									{{addrs[addrIndex].province}}{{addrs[addrIndex].city}}{{addrs[addrIndex].region}}{{addrs[addrIndex].address}}
								</span>
							</div>
							<div class="addr" v-if="addrs.length>0">
								收货人:
								<span>{{addrs[addrIndex].name}}</span>
								<span>{{addrs[addrIndex].phone}}</span>
							</div>
							<el-button type="danger" :loading="loading" class="btn"
								@click="loading?'':submit()">{{loading?'订单处理中':'提交订单'}}</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<el-dialog title="" :visible.sync="open" width="30%">
			<div v-if="orderPay&&showPay" class="pay-code">
				<div class="tip">请打开微信扫码支付</div>
				<div class="tip">剩余:{{minute}}分{{second}}秒</div>
				<img :src="orderPay.codeUrl" />
				<div class="tip">微信付款二维码</div>
			</div>
		</el-dialog>

		<Footer></Footer>
	</div>
</template>

<script>
	import {
		setCookie,
		getCookie,
		delCookie
	} from '@/utils/auth'
	import {
		addrList
	} from '@/api/member/member'
	import {
		orderItemView,
		orderItemCreate,
		orderStatus
	} from '@/api/order/order'
	export default {
		data() {
			return {
				name: '',
				addrs: [],
				addrIndex: 0,
				orderData: [],
				cartIds: [],
				remark: '',
				orderPay: null,
				loading: false,
				showPay: false,
				minute: '',
				second: '',
				param: null,
				recipel: {
					name: '',
					card: '',
					phone: ''
				},
				open: false
			};
		},
		created() {
			this.cartIds = getCookie("cartIds") ? JSON.parse(getCookie("cartIds")) : []
			this.param = getCookie("orderItem") ? JSON.parse(getCookie("orderItem")) : []
			this.getAddr()
			this.getOrderView()
		},
		methods: {
			search() {
				this.$router.push({
					path: '/item/search?name=' + this.name
				})
			},
			toUrl(url) {
				this.$router.push({
					path: url
				})
			},
			toQuestion() {
				this.$router.push({
					name: 'question',
					params: {
						param: JSON.stringify(this.param),
						cartIds: this.cartIds
					}
				})
			},
			addr() {
				this.$router.push({
					name: 'addr',
					params: {
						param: JSON.stringify(this.param),
						cartIds: this.cartIds
					}
				})
			},
			getAddr() {
				addrList({}).then(res => {
					this.addrs = res.data
				})
			},
			getOrderView() {
				orderItemView({
					shopList: this.param
				}).then(res => {
					this.orderData = res.data
				})
			},
			getPrice() {
				var price = 0
				if (this.orderData.length > 0) {
					this.orderData.forEach(data => {
						data.list.forEach(item => {
							price += (item.isPrice * 100 * item.count)
						})
						price += data.totalFreight * 100
					})
				}
				return price / 100
			},
			getFrePrice() {
				var price = 0
				if (this.orderData.length > 0) {
					this.orderData.forEach(data => {
						price += data.totalFreight * 100
					})
				}
				return price / 100
			},
			submit() {
				if (this.loading) {
					return
				}
				if (this.addrs.length == 0) {
					this.$message({
						message: '请选择收货地址',
						type: 'error'
					});
					return
				}
				//判断是否填写处方单
				if (this.orderData[0].hasPreDrug == 1) {
					if (!this.recipel.name) {
						this.$message({
							message: '请填写与处方单一致的姓名',
							type: 'error'
						});
						return
					}
					if (!this.recipel.phone) {
						this.$message({
							message: '请填写与处方单一致的联系电话',
							type: 'error'
						});
						return
					}
					if (!this.recipel.card) {
						this.$message({
							message: '请填写与处方单一致的身份证号',
							type: 'error'
						});
						return
					}
				}

				var data = {
					cartIds: this.cartIds,
					shopList: [],
					isUseAmount: 0,
					isUseCard: 0,
					cardId: null,
					name: this.addrs[this.addrIndex].name,
					phone: this.addrs[this.addrIndex].phone,
					addr: this.addrs[this.addrIndex].province + this.addrs[this.addrIndex].city + this.addrs[this
						.addrIndex].region + this.addrs[this.addrIndex].address,
					recipel: this.recipel
				}
				this.orderData.forEach(shop => {
					var list = {
						shopId: shop.shop.id,
						couponId: null,
						itemList: [],
						useIntegral: 0,
						remark: this.remark
					}
					shop.list.forEach(item => {
						list.itemList.push({
							skuId: item.skuId,
							count: item.count
						})
					})
					data.shopList.push(list)
				})
				this.loading = true
				orderItemCreate(data).then(res => {
					if (res.code == -1) {
						this.$message({
							message: '订单创建失败',
							type: 'error'
						});
						this.loading = false
						this.showPay = false
						this.open = false
						return
					}
					var resData = JSON.parse(res.data)
					this.orderPay = resData
					this.loading = false
					this.showPay = true
					this.open = true
					this.timeBack()
				})
			},
			timeBack() {
				var intDiff = parseInt(14 * 60 + 59); //倒计时总秒数量
				var that = this
				var intv = setInterval(function() {
					intDiff = intDiff - 1
					if (intDiff == 0) {
						clearInterval(intv)
						that.toUrl('/order/order')
					}
					//获取订单支付状态
					orderStatus({
						orderIds: that.orderPay.orderId
					}).then(res => {
						if (res.data == 2) {
							clearInterval(intv)
							that.$message({
								message: '支付成功',
								type: 'success'
							});
							that.toUrl('/order/order')
						}
					})
					var day = 0,
						hour = 0,
						minute = 0,
						second = 0; //时间默认值
					if (intDiff > 0) {
						//计算相关的天，小时，还有分钟，以及秒
						day = Math.floor(intDiff / (60 * 60 * 24));
						hour = Math.floor(intDiff / (60 * 60)) - (day * 24);
						minute = Math.floor(intDiff / 60) - (day * 24 * 60) - (hour * 60);
						second = Math.floor(intDiff) - (day * 24 * 60 * 60) - (hour * 60 * 60) - (minute * 60);
					}
					if (minute <= 9) minute = '0' + minute;
					if (second <= 9) second = '0' + second;
					that.minute = minute
					that.second = second

					if (that.minute == 0 && that.second == 0) {
						that.open = false
						that.$message({
							message: '付款码超时,请重新下单',
							type: 'success'
						});
						clearInterval(intv)
					}
				}, 1000)
			}
		},
	};
</script>

<style lang='scss'>
	.order-confirm-content {
		width: 80%;
		margin-left: 10%;

		.addr-box {
			margin-bottom: 30px;
			margin-top: 30px;

			.border-bottom {
				border-bottom: 2px solid #f1f1f1;
			}

			.header-wrapper {
				position: relative;
				margin-bottom: 15px;

				.header-title {
					line-height: 25px;
					color: #333;
					font-weight: 700;
					font-size: 14px;

					.header-operation {
						position: absolute;
						right: 30%;
						cursor: pointer;
						font-size: 12px;
						font-weight: 400;
						color: #36c;
					}

					.header-operation:hover {
						text-decoration: underline;
					}
				}
			}

			.addr-list {
				width: 100%;

				.addr {
					margin: 0 0 0 4px;
					vertical-align: middle;
					line-height: 1;
					color: #333;
					font-size: 14px;
					line-height: 30px;
				}

				.name {
					margin: 0 0 0 4px;
					vertical-align: middle;
					line-height: 1;
					color: #333;
					font-size: 14px;
					line-height: 30px;
				}

				.phone {
					color: grey;
					font-size: 14px;
					margin: 0 0 0 4px;
					vertical-align: middle;
					line-height: 30px;
				}

				.addr-select {
					span {
						font-weight: 700 !important;
						color: #f40;
					}
				}
			}
		}

		.item-box {
			margin-bottom: 30px;
			margin-top: 30px;

			.border-bottom {
				border-bottom: 2px solid #f1f1f1;
			}

			.header-wrapper {
				position: relative;
				margin-bottom: 15px;

				.header-title {
					line-height: 25px;
					color: #333;
					font-weight: 700;
					font-size: 14px;

					.header-operation {
						position: absolute;
						right: 30%;
						cursor: pointer;
						font-size: 12px;
						font-weight: 400;
						color: #36c;
					}
				}
			}

			.item-headers-wrap {
				margin-top: 15px;
				color: #6c6c6c;

				.item-headers-content {
					display: inline-block;
					height: 24px;
					line-height: 24px;
					text-align: center;
					border-bottom: 3px solid #b2d1ff;
					font-size: 12px;
					margin-left: 1px;
					vertical-align: bottom;
				}

				.item-headers-0 {
					width: 40%;
					margin-left: 0;
				}

				.item-headers-1 {
					width: 15%;
				}

				.item-headers-2,
				.item-headers-3 {
					width: 15%;
				}

				.item-headers-5 {
					width: 10%;
				}
			}

			.item-row {
				width: 100%;
				margin-top: 20px;
				border-bottom: 1px solid #e8e8e8;

				.prd-drug {
					cursor: pointer;

					span {
						color: #dd2727;
						font-size: 14px;
					}

					span:hover {
						font-weight: bold;
					}
				}

				.item-info {
					display: inline-block;
					vertical-align: top;
					width: 100%;

					.info-cell {
						display: inline-block;
						box-sizing: content-box;
						margin-right: 1px;
					}

					.item-detail {
						width: 40%;
						padding: 10px 0 10px 10px;

						.td-inner {
							.info-img {
								background: #fff;
								width: 60px;
								height: 60px;
								-webkit-border-radius: 3px;
								-moz-border-radius: 3px;
								-ms-border-radius: 3px;
								border-radius: 3px;
								border: 1px solid #eee;
								float: left;
								overflow: hidden;

								img {
									height: 60px;
									width: 60px;
									vertical-align: middle;
								}
							}

							.info-title {
								margin: -3px 0 0 75px;
								padding-right: 12px;

								.item-basic-info {
									font-size: 14px;
									display: block;
									max-height: 36px;
									overflow: hidden;
									text-overflow: ellipsis;
									color: #3c3c3c;
								}
							}

						}
					}

					.info-sku {
						color: #6c6c6c;
						width: 15%;
						display: inline-block;
						vertical-align: top;
						padding: 10px 0;
						text-align: center;

						.bd {
							word-break: break-all;
						}
					}

					.info-price {
						color: #3c3c3c;
						vertical-align: top;
						display: inline-block;
						width: 15%;
						text-align: center;
						padding: 10px 0;
						font-size: 14px;
					}

					.order-quantity {
						display: inline-block;
						vertical-align: top;
						text-align: center;
						width: 15%;
						padding: 10px 0;
						margin-right: 1px;
						font-size: 14px;
					}

					.item-row__price {
						display: inline-block;
						width: 10%;
						text-align: right;
						padding-right: 5px;
						font-weight: bold;
						color: rgb(255, 68, 0);
						font-size: 14px;
					}
				}

				.total {
					text-align: right;
					font-size: 14px;
					line-height: 40px;

					span {
						color: #333;
					}

					.price {
						color: rgb(255, 68, 0);
						padding-left: 5px;
						font-weight: bold;
						font-size: 18px;
					}
				}
			}

			.pay-box {
				text-align: right;
				margin-top: 15px;

				.box__wrapper {
					display: inline-block;
					border: 1px solid #ff0036;
					border-radius: 2px;

					.box__shadow {
						border: 3px solid #fff0e8;
						min-width: 230px;
						padding: 10px 5px 10px 20px;

						.pay-price {
							font-size: 14px;

							span {
								font-size: 24px;
								font-weight: bold;
								color: #ff0036;
								padding-left: 10px;
							}
						}

						.addr {
							line-height: 1.6;
							margin-top: 10px;
							font-weight: 700;
							color: #333;

							span {
								padding-left: 5px;
								font-weight: normal;
							}
						}

						.btn {
							width: 182px;
							cursor: pointer;
							text-align: center;
							font-size: 14px;
							font-weight: 700;
							background: #dd2727;
							color: #fff;
							background-color: rgb(255, 80, 0);
							margin-top: 10px;
							letter-spacing: 5px;
						}
					}
				}
			}

		}
	}


	.pay-code {
		text-align: center;
		margin-top: 15px;

		.tip {
			color: red;
			font-size: 14px;
			font-weight: bold;
			line-height: 20px;
		}

		img {
			width: 200px;
			height: 200px;
		}
	}

	.recipel {
		width: 500px;

		.tip {
			padding: 20px;
			color: red;
		}
	}
</style>