import request from '@/utils/request'

export function orderItemView(params) {
	return request({
		url: '/order/item/view',
		method: 'post',
		data: params
	})
}

export function orderItemCreate(params) {
	return request({
		url: '/order/item/create',
		method: 'post',
		data: params
	})
}

export function orderStatus(params) {
	return request({
		url: '/order/get/status',
		method: 'post',
		params
	})
}

export function orderList(params) {
	return request({
		url: '/order/list',
		method: 'post',
		data: params
	})
}

export function orderScrap(params) {
	return request({
		url: '/order/scrap',
		method: 'post',
		params
	})
}

export function orderCancel(params) {
	return request({
		url: '/order/cancel',
		method: 'post',
		params
	})
}

export function orderDel(params) {
	return request({
		url: '/order/del',
		method: 'post',
		params
	})
}